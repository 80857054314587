export const VALID_LAYOUT_VALUES = [
    'fill',
    'fixed',
    'intrinsic',
    'responsive',
    'raw',
    undefined,
] as const;

export const VALID_LOADING_VALUES = ['lazy', 'eager', undefined] as const;

export const DEVICE_SIZES = [750, 828, 1080, 1200, 1920, 2048, 3840];

export const IMAGE_SIZES = [16, 32, 48, 64, 96, 128, 256, 384];

export const EMPTY_DATA_URL =
    'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
