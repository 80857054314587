import { version } from 'react';

export const normalizeSrc = (src: string): string => {
    return src[0] === '/' ? src.slice(1) : src;
};

/**
 * Removes the trailing slash of a path if there is one. Preserves the root path `/`.
 */
export function removePathTrailingSlash(path: string): string {
    return path.endsWith('/') && path !== '/' ? path.slice(0, -1) : path;
}

/**
 * Normalizes the trailing slash of a path
 */
export const normalizePathTrailingSlash = (path: string): string => {
    if (/\.[^/]+\/?$/.test(path)) {
        return removePathTrailingSlash(path);
    } else if (path.endsWith('/')) {
        return path;
    } else {
        return path + '/';
    }
};

export const getTwObjectFit = (fit: string | undefined): string => {
    switch (fit) {
        case 'fill':
            return 'object-fill';
        case 'contain':
            return 'object-contain';
        case 'cover':
            return 'object-cover';
        case 'scale-down':
            return 'object-scale-down';
        default:
            return 'object-none';
    }
};

export const getDynamicProps = (
    fetchPriority?: string,
): Record<string, string | undefined> => {
    const [majorStr, minorStr] = version.split('.');

    const major = majorStr !== undefined ? parseInt(majorStr, 10) : 18;
    const minor = minorStr !== undefined ? parseInt(minorStr, 10) : 3;

    if (major > 18 || (major === 18 && minor >= 3)) {
        // In React 18.3.0 or newer, we must use camelCase
        // prop to avoid "Warning: Invalid DOM property".
        // See https://github.com/facebook/react/pull/25927
        return { fetchPriority };
    }

    // In React 18.2.0 or older, we must use lowercase prop
    // to avoid "Warning: Invalid DOM property".
    return { fetchpriority: fetchPriority };
};
