// See https://stackoverflow.com/q/39777833/266535 for why we use this ref
// handler instead of the img's onLoad attribute.
import type { ImgElementWithDataProp, OnLoad, placeholderType } from '../types';

export const handleLoading = (
    img: ImgElementWithDataProp,
    placeholder: placeholderType,
    setPlaceholderComplete: (b: boolean) => void,
    onLoadRef: React.MutableRefObject<OnLoad | undefined>,
) => {
    const src = img?.src;
    if (!img || img['data-loaded-src'] === src) return;
    img['data-loaded-src'] = src;

    const p = 'decode' in img ? img.decode() : Promise.resolve();

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    p.catch(() => {}).then(() => {
        if (!img.parentNode || !img.isConnected) {
            // Exit early in case of race condition:
            // - onload() is called
            // - decode() is called but incomplete
            // - unmount is called
            // - decode() completes
            return;
        }

        if (!placeholder) {
            setPlaceholderComplete(true);
        }

        if (onLoadRef?.current) {
            // Since we don't have the SyntheticEvent here,
            // we must create one with the same shape.
            // See https://reactjs.org/docs/events.html
            const event = new Event('load');

            Object.defineProperty(event, 'target', {
                writable: false,
                value: img,
            });

            let prevented = false;
            let stopped = false;

            onLoadRef.current({
                ...event,
                nativeEvent: event,
                currentTarget: img,
                target: img,
                isDefaultPrevented: () => prevented,
                isPropagationStopped: () => stopped,
                persist: () => void 0,
                preventDefault: () => {
                    prevented = true;
                    event.preventDefault();
                },
                stopPropagation: () => {
                    stopped = true;
                    event.stopPropagation();
                },
            });
        }
    });
};
