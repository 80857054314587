import * as React from 'react';
import type { ImageElementProps, ImgElementWithDataProp } from './types';
import { getDynamicProps } from './utils/image-utils';
import { handleLoading } from './utils/handle-loading';
import { ImageOff } from 'lucide-react';

/**
 * An Image is a graphic representation of something.
 *
 * @accessibility
 * If image should be visible to screen readers, textual representation needs to be provided in 'alt' property.
 *
 * Other considerations:
 *  - when alt property is empty, then Narrator in scan mode navigates to image and narrates it as empty paragraph.
 *  - when image has role='presentation' then screen readers navigate to the element in scan/virtual mode. To avoid this, the attribute "aria-hidden='true'" is applied by the default image behavior.
 *  - when alt property is used in combination with aria-label, arialabbeledby or title, additional screen readers verification is needed as each screen reader handles this combination differently.
 */
export const ImageElement = React.forwardRef<
    HTMLImageElement,
    ImageElementProps
>((props, forwardedRef) => {
    const {
        alt,
        src,
        srcSet,
        sizesInput,
        height,
        width,
        decoding,
        className,
        fetchPriority,
        placeholder,
        setPlaceholderComplete,
        loading,
        onLoadRef,
        showAltText,
        setShowAltText,
        onError,
        ...rest
    } = props;

    const handleImageLoading = React.useCallback(
        (img: ImgElementWithDataProp) => {
            handleLoading(img, placeholder, setPlaceholderComplete, onLoadRef);
        },
        [placeholder, setPlaceholderComplete, onLoadRef],
    );

    const imageRef = React.useCallback(
        (img: ImgElementWithDataProp | null) => {
            if (forwardedRef) {
                if (typeof forwardedRef === 'function') forwardedRef(img);
                else if (typeof forwardedRef === 'object') {
                    forwardedRef.current = img;
                }
            }

            if (!img) {
                return;
            }

            if (onError) {
                // If the image has an error before react hydrates, then the error is lost.
                // The workaround is to wait until the image is mounted which is after hydration,
                // then we set the src again to trigger the error handler (if there was an error).
                // eslint-disable-next-line no-self-assign
                img.src = img.src;
            }

            if (img.complete) {
                handleImageLoading(img);
            }
        },
        [onError, forwardedRef, handleImageLoading],
    );

    const image = (className: string | undefined = undefined) => (
        <img
            {...rest}
            {...getDynamicProps(fetchPriority)}
            // It's intended to keep `loading` before `src` because React updates
            // props in order which causes Safari/Firefox to not lazy load properly.
            // See https://github.com/facebook/react/issues/25883
            loading={loading}
            width={width}
            height={height}
            decoding={decoding}
            className={className}
            // It's intended to keep `src` the last attribute because React updates
            // attributes in order. If we keep `src` the first one, Safari will
            // immediately start to fetch `src`, before `sizes` and `srcSet` are even
            // updated by React. That causes multiple unnecessary requests if `srcSet`
            // and `sizes` are defined.
            // This bug cannot be reproduced in Chrome or Firefox.
            sizes={sizesInput}
            srcSet={srcSet}
            src={src}
            alt={alt}
            ref={imageRef}
            onLoad={event => {
                const img = event.currentTarget as ImgElementWithDataProp;
                img && handleImageLoading(img);
            }}
            onError={event => {
                // if the real image fails to load, this will ensure "alt" is visible
                setShowAltText(true);

                // If the real image fails to load, this will still remove the placeholder.
                setPlaceholderComplete(true);

                if (onError) {
                    onError(event);
                }
            }}
        />
    );

    return showAltText ? (
        <div
            className={`absolute flex flex-col justify-center items-center`}
            style={{ width, height }}
        >
            <ImageOff size={48} className="text-red-500" />
            <span className="z-10 text-red-500 mt-2">{alt}</span>
        </div>
    ) : (
        image(className)
    );
});

ImageElement.displayName = 'ImageElement';
