import React, { createContext } from 'react';
import type { ImageConfig } from '../types';
import { DEVICE_SIZES, IMAGE_SIZES } from '../image-constants';
import { archiproImageLoader } from '../loaders';

export const imageDefaultConfig = {
    deviceSizes: DEVICE_SIZES,
    imageSizes: IMAGE_SIZES,
    loader: archiproImageLoader('design.archipro.co.nz'),
    isProduction: false,
};

const ImageConfigContext = createContext<ImageConfig>(imageDefaultConfig);

export const ImageConfigProvider = ({
    children,
    value,
}: {
    children: React.ReactNode;
    value: ImageConfig;
}) => {
    return (
        <ImageConfigContext.Provider value={value}>
            {children}
        </ImageConfigContext.Provider>
    );
};

export const useImageConfig = () => {
    const value = React.useContext(ImageConfigContext);
    if (value === undefined) {
        throw new Error(
            'useImageConfig must be used within a ImageConfigContext'
        );
    }

    return value;
};
