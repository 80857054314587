import type { ImageLoader } from './types';
import { normalizeSrc } from './utils/image-utils';

export const archiproImageLoader = (
    domain: string,
    initialQuality = 80,
    notEscapeParams = false
): ImageLoader => {
    // locally we use a full path with a set domain (design.archipro.co.nz) to be able to display images from http://localhost, on production we use a relative path
    const urlPath = domain
        ? `https://${domain}/images/cdn-images`
        : `/images/cdn-images`;

    return ({ src, width, quality }) => {
        // Demo: https://archipro.co.nz/images/cdn-images/width=200,quality=50/<URL>
        const params = [
            `width=${width}`,
            `quality=${quality || initialQuality}`,
        ];
        const paramsString = notEscapeParams
            ? params.join(',')
            : encodeURIComponent(params.join(','));
        return `${urlPath}/${paramsString}/${normalizeSrc(src)}`;
    };
};
