import {
    GenImgAttrsData,
    GenImgAttrsResult,
    ImageConfigComplete,
    ImageProps,
    ImgProps,
    placeholderType,
} from '../types';
import { getImageBlurSvg } from './get-image-blur';
import { cn } from '../../../utils/cn';
import { getTwObjectFit } from './image-utils';

/**
 * A shared function, used on both client and server, to generate the props for <img>.
 */
export function getImgProps(
    imageProps: ImageProps,
    className: string,
    placeholderClassName: string,
    _state: {
        imgConf: ImageConfigComplete;
        placeholderComplete: boolean;
    },
): {
    props: ImgProps;
    meta: {
        priority: boolean;
        placeholder: placeholderType;
        fill: boolean;
    };
    excludedProps: {
        multiplied: boolean | null;
    };
} {
    const {
        src,
        sizes,
        priority = false,
        loading,
        quality,
        width,
        height,
        fill = false,
        overrideSrc,
        placeholder = undefined,
        fetchPriority,
        fit,
        blurDataURL,
        multiplied = false,
        ...rest
    } = imageProps;

    const { imgConf: config, placeholderComplete } = _state;

    const showPlaceholder = placeholder && !placeholderComplete;
    const isBlur = showPlaceholder && placeholder === 'blur';

    // Remove property so it's not spread on <img> element
    delete (rest as any).srcSet; // eslint-disable-line

    // set background image if placeholder is provided
    const backgroundImage = isBlur
        ? `url("data:image/svg+xml;charset=utf-8,${getImageBlurSvg({
              width,
              height,
              blurDataURL: blurDataURL || '', // assume not undefined
          })}")`
        : undefined;

    // placeholder class names
    const placeholderClassNames = backgroundImage
        ? ['bg-cover', 'bg-no-repeat', 'bg-center']
        : [placeholderClassName];

    // base image class names
    const imgClassNames = fill
        ? [
              'absolute',
              'size-full',
              'inset-x-0',
              'inset-y-0',
              getTwObjectFit(fit),
          ]
        : [];

    if (!showPlaceholder) {
        placeholderClassNames.push('animate-fade-in');
    }

    const imgAttributes = generateImgAttrs({
        config,
        src,
        width,
        quality,
        sizes,
    });

    return {
        props: {
            ...rest,
            loading,
            fetchPriority,
            width,
            height,
            decoding: 'async',
            className: cn(imgClassNames, placeholderClassNames, className),
            style: { backgroundImage },
            sizes: imgAttributes.sizes,
            srcSet: overrideSrc || imgAttributes.srcSet,
            src: overrideSrc || imgAttributes.src,
        },
        meta: {
            priority,
            placeholder,
            fill,
        },
        excludedProps: {
            multiplied,
        },
    };
}

function generateImgAttrs({
    config,
    src,
    width,
    quality,
    sizes,
}: GenImgAttrsData): GenImgAttrsResult {
    if (!src) {
        return { src, srcSet: undefined, sizes: undefined };
    }

    const { loader } = config;
    const { widths, kind } = getWidths(config, width, sizes);
    const lastWidthIndex = widths.length - 1;
    const lastWidth = widths[lastWidthIndex] ?? 0;

    return {
        sizes: !sizes && kind === 'w' ? '100vw' : sizes,
        srcSet: widths
            .map(
                (w, i) =>
                    `${loader({ src, quality, width: w })} ${
                        kind === 'w' ? w : i + 1
                    }${kind}`,
            )
            .join(', '),

        // It's intended to keep `src` the last attribute because React updates
        // attributes in order. If we keep `src` the first one, Safari will
        // immediately start to fetch `src`, before `sizes` and `srcSet` are even
        // updated by React. That causes multiple unnecessary requests if `srcSet`
        // and `sizes` are defined.
        // This bug cannot be reproduced in Chrome or Firefox.
        src: loader({ src, quality, width: lastWidth }),
    };
}

export const getWidths = (
    { deviceSizes, allSizes }: ImageConfigComplete,
    width?: number,
    sizes?: string,
): { widths: number[]; kind: 'w' | 'x' } => {
    if (sizes) {
        // Find all the "vw" percent sizes used in the sizes prop
        const viewportWidthRe = /(^|\s)(1?\d?\d)vw/g;
        const percentSizes: number[] = [];

        for (let match; (match = viewportWidthRe.exec(sizes)); match) {
            const m = typeof match[2] == 'string' ? match[2] : null;

            if (m) {
                percentSizes.push(parseInt(m));
            }
        }

        if (percentSizes.length) {
            const smallestRatio = Math.min(...percentSizes) * 0.01;

            return {
                widths: allSizes.filter(
                    s => s >= (deviceSizes[0] ?? 0) * smallestRatio,
                ),
                kind: 'w',
            };
        }

        return { widths: allSizes, kind: 'w' };
    }

    if (typeof width !== 'number') {
        return { widths: deviceSizes, kind: 'w' };
    }

    const widths = [
        ...new Set(
            // > This means that most OLED screens that say they are 3x resolution,
            // > are actually 3x in the green color, but only 1.5x in the red and
            // > blue colors. Showing a 3x resolution image in the app vs a 2x
            // > resolution image will be visually the same, though the 3x image
            // > takes significantly more data. Even true 3x resolution screens are
            // > wasteful as the human eye cannot see that level of detail without
            // > something like a magnifying glass.
            // https://blog.twitter.com/engineering/en_us/topics/infrastructure/2019/capping-image-fidelity-on-ultra-high-resolution-devices.html
            [width, width * 2 /*, width * 3*/].map(
                w =>
                    allSizes.find(p => p >= w) || allSizes[allSizes.length - 1],
            ),
        ),
    ];

    return {
        widths: widths.filter((w): w is number => w !== undefined),
        kind: 'x',
    };
};
