import * as React from 'react';
import { ImageElement } from './ImageElement';
import type { ImageConfigComplete, ImageProps } from './types';
import { useImageConfig } from './Context/image-config-context';
import { getImgProps } from './utils/get-image-props';
import { cva } from 'class-variance-authority';

export const imageVariants = cva('rocco-image', {
    variants: {
        variant: {
            default: '',
        },
        multiplied: {
            true: 'mix-blend-multiply',
        },
        size: {
            professionalLogo: 'w-[75%] h-auto',
            full: 'size-full',
            default: '',
        },
        fit: {
            cover: 'object-cover',
            contain: 'object-contain',
            default: '',
        },
    },
    defaultVariants: {
        variant: 'default',
        size: 'default',
        fit: 'default',
    },
});

export const placeholderVariants = cva('', {
    variants: {
        placeholderVariant: {
            light: 'bg-gray-200',
            dark: 'bg-gray-800',
            transparent: 'background-transparent',
        },
    },
    defaultVariants: {
        placeholderVariant: 'transparent',
    },
});

/**
 * An Image is a graphic representation of something.
 * It can be used to display images in a variety of ways.
 */
export const Image = React.forwardRef<HTMLImageElement, ImageProps>(
    (props, forwardedRef) => {
        const { variant, placeholderVariant, multiplied, onLoad, size, fit } =
            props;

        const imageConfigContext = useImageConfig();

        const config: ImageConfigComplete = React.useMemo(() => {
            if (!imageConfigContext) {
                throw new Error('Image component requires imageConfig');
            }

            const c = imageConfigContext;
            const {
                deviceSizes: configDeviceSizes = [],
                imageSizes: configImageSizes = [],
            } = c;

            const allSizes = [
                ...configDeviceSizes,
                ...configImageSizes,
                props.width ?? 0,
                props.height ?? 0,
            ].sort((a, b) => a - b);

            const deviceSizes = configDeviceSizes.sort((a, b) => a - b);

            return { ...c, allSizes: [...new Set(allSizes)], deviceSizes };
        }, [imageConfigContext]);

        const onLoadRef = React.useRef(onLoad);

        React.useEffect(() => {
            onLoadRef.current = onLoad;
        }, [onLoad]);

        const [placeholderComplete, setPlaceholderComplete] =
            React.useState(false);
        const [showAltText, setShowAltText] = React.useState(false);

        const imgPropsConfig = {
            imgConf: config,
            placeholderComplete,
        };

        const { props: imgAttributes, meta: imgMeta } = getImgProps(
            props,
            imageVariants({ variant, multiplied, size, fit }),
            placeholderVariants({ placeholderVariant }),
            imgPropsConfig,
        );

        return (
            <ImageElement
                {...imgAttributes}
                placeholder={imgMeta.placeholder}
                setPlaceholderComplete={setPlaceholderComplete}
                onLoadRef={onLoadRef}
                showAltText={showAltText}
                setShowAltText={setShowAltText}
                sizesInput={props.sizes}
                ref={forwardedRef}
            />
        );
    },
);

Image.displayName = 'Image';
